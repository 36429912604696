import React, { useState } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from './billingFilterBox.module.scss'

const CloudSummaryFilterBox = (props) => {

    const [summaryDateCustomCheck, setSummaryDateCustomCheck] = useState(false);      // check state for custom chosen in summary date
    const [dueDateCustomCheck, setDueDateCustomCheck] = useState(false);              // check state for custom chosen in due date  
    const date = new Date();                                                          // for js date time function

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "custom") {
            dateCheck(true);
        }
        else {
            from(null);
            to(null);
        }
    }

    const [searchServiceValue, setSearchServiceValue] = useState(""); // Searched Value from Service
    const clearFilterSearch = () => { // Clear Filter Box Search Input
        setSearchServiceValue("");
    }

    // Date Array
    const dateArray = [
        {
            id: "0",
            value: "today",
            name: "Today"
        },
        {
            id: "1",
            value: "yesterday",
            name: "Yesterday"
        },
        {
            id: "2",
            value: "last7",
            name: "Last 7 Days"
        },
        {
            id: "3",
            value: "last30",
            name: "Last 30 Days"
        },
        {
            id: "4",
            value: "thismonth",
            name: "This Month"
        },
        {
            id: "5",
            value: "lastmonth",
            name: "Last Month"
        },
        {
            id: "6",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props?.serviceId && props.serviceId !== null) { 
            if(document.getElementById(props?.serviceId))
            document.getElementById(props?.serviceId).checked = false;
        };

        if (props.summaryDateLastUsedDate !== "default") {
            props.setSummaryDateLastUsedDate("default");
            document.getElementById(props.summaryDateLastUsedDate).checked = false;
        }

        if (props.dueDateLastUsedDate !== "default") {
            props.setDueDateLastUsedDate("default");
            document.getElementById("due_date_" + props.dueDateLastUsedDate).checked = false;
        }
        props.setScrollToDivTop(true);
        props.setServiceId(null);
        props.setSummaryDateFrom(null);
        props.setSummaryDateTo(null);
        props.setDueDateFrom(null);
        props.setDueDateTo(null);
        props.setSummaryDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setDueDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setDueDateCustomToLast(date.toISOString().slice(0, 10));
        props.setSummaryDateCustomToLast(date.toISOString().slice(0, 10));
        setSummaryDateCustomCheck(false);
        setDueDateCustomCheck(false);
        props.setPageNo(1);
        props.setResetPaginationToggle(true);
        props.GetTableData(1, props.pageSize, props.searchValueTable, null, null, null, null, null, props.sortCol, props.sortDir);
        props.setResetPaginationToggle(false);
        props.setFilterFlag(false);
    }


    return (
        <div className="filter-box cloudsummery-listing">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Service</h3>

                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchServiceValue}
                                    onChange={(e) => setSearchServiceValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearch}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    <>

                                        {
                                            props.serviceData &&
                                            props.serviceData.filter(option => (option.name).toLowerCase().includes(searchServiceValue.toLowerCase())).map((filteredOption) => {
                                                if (filteredOption.serviceType == "ONLINE") {
                                                    return (
                                                        <>
                                                            <Form.Check // prettier-ignore
                                                                key={filteredOption.id}
                                                                type={'radio'}
                                                                id={filteredOption.id}
                                                                label={filteredOption.name}
                                                                name="servicesGroup"
                                                                value={filteredOption.id}
                                                                aria-label={filteredOption.name}
                                                                defaultChecked={filteredOption.id == props.serviceId ? true : false}
                                                                onChange={(e) => {
                                                                    (e.target.value === "default") ? props.setServiceId(null) : props.setServiceId(e.target.id);
                                                                }
                                                                }
                                                            />
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                }

                                {
                                    !props.serviceData && <p>Loading...</p>
                                }
                            </div>
                        </div>

                    </Col>
                    <Col className="filter-column">
                        <h3 className="mb-3">Summary Date</h3>
                        <div>

                            <Form>
                                <div className="filter-options date-filter-options">
                                    {
                                        dateArray.map(summeryDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={summeryDate.value}
                                                        type={'radio'}
                                                        id={summeryDate.value}
                                                        label={summeryDate.name}
                                                        name="summaryDateGroup"
                                                        value={summeryDate.value}
                                                        aria-label={summeryDate.name}
                                                        defaultChecked={summeryDate.value == props.summaryDateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setSummaryDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setSummaryDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }
                                                            DatePicker(e, props.setSummaryDateFrom, props.setSummaryDateTo, setSummaryDateCustomCheck); props.setSummaryDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )

                                        })
                                    }

                                    {
                                        (summaryDateCustomCheck || props.summaryDateLastUsedDate === "custom") &&
                                        <>
                                            <div className="pr-4">
                                                <p className='mt-2'>From :</p>
                                                <Form.Control value={props.summaryDateCustomFromLast} max="2999-12-31" type="date"
                                                    onChange={(e) => {
                                                        if (!(e.target.value)) {
                                                            props.setSummaryDateCustomFromLast(null);
                                                            props.setSummaryDateFrom(null);
                                                        }
                                                        else {
                                                            props.setSummaryDateCustomFromLast(e.target.value);
                                                            let date = (e.target.value.split("-")).reverse();
                                                            props.setSummaryDateFrom(String(date[0] + "/" + date[1] + "/" + date[2]));
                                                        }
                                                    }} />
                                                <p className='mt-2'>To :</p>
                                                <Form.Control value={props.summaryDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setSummaryDateCustomToLast(null); props.setSummaryDateTo(null); } else { props.setSummaryDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setSummaryDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col className="filter-column">
                        <h3 className="mb-3">Due Date</h3>
                        <div>
                            <Form>
                                <div className="filter-options date-filter-options">
                                    {
                                        dateArray.map(dueDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={dueDate.value}
                                                        type={'radio'}
                                                        id={"due_date_" + dueDate.value}
                                                        label={dueDate.name}
                                                        name="dueDateGroup"
                                                        value={dueDate.value}
                                                        aria-label={dueDate.id}
                                                        defaultChecked={dueDate.value == props.dueDateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setDueDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setDueDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }
                                                            DatePicker(e, props.setDueDateFrom, props.setDueDateTo, setDueDateCustomCheck); props.setDueDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )

                                        })
                                    }

                                    {
                                        (dueDateCustomCheck || props.dueDateLastUsedDate === "custom") &&
                                        <>
                                            <div className="pr-4">
                                                <p className='mt-2'>From :</p>
                                                <Form.Control value={props.dueDateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => {
                                                    if (!(e.target.value)) { props.setDueDateCustomFromLast(null); props.setDueDateFrom(null); }
                                                    else { props.setDueDateCustomFromLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setDueDateFrom(String(date[0] + "/" + date[1] + "/" + date[2])); }
                                                }} />
                                                <p className='mt-2'>To :</p>
                                                <Form.Control value={props.dueDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setDueDateCustomToLast(null); props.setDueDateTo(null); } else { props.setDueDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setDueDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </Form>

                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.serviceId && !props.summaryDateFrom && !props.summaryDateTo && !props.dueDateFrom && !props.dueDateTo) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.serviceId || props.summaryDateFrom || props.summaryDateTo || props.dueDateFrom || props.dueDateTo) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.serviceId || props.summaryDateFrom || props.summaryDateTo || props.dueDateFrom || props.dueDateTo) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setPageNo(1);
                                    props.setScrollToDivTop(true);
                                    props.setResetPaginationToggle(true);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.serviceId, props.summaryDateFrom, props.summaryDateTo, props.dueDateFrom, props.dueDateTo, props.sortCol, props.sortDir, false, true);
                                    props.setResetPaginationToggle(false);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            // disabled
                            >
                                Apply
                            </Button>
                        }
                        {(!props.serviceId && !props.summaryDateFrom && !props.summaryDateTo && !props.dueDateFrom && !props.dueDateTo) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default CloudSummaryFilterBox