import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { CLOUD_INVOICE_LISTING_TABLE, CUSTOMER_DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT, CUSTOMER_DASHBOARD_UNPAID_INVOICES, GET_CUSTOMERS_LIST, UNPAID_INVOICE_STATUS } from '../../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../../Utils/API';
import { useLocation } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import SummaryFilterBox from './LocalComponents/FilterBox';
import Toast from '../../../../../../../../../GlobalComponents/Toast';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { styled } from '@mui/material';
import LazyTable from './LocalComponents/LazyTable';
import { BACKGROUND_COLOR_SECONDARY, REACT_DATA_TABLE_COLOR_THEME, TEXT_SECONDARY, TOAST_ERROR_ICON_COLOR, TOAST_SUCCESS_ICON_COLOR, WIDGETS_LABEL_FILL_COLOR, WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR } from '../../../../../../../../../Utils/ColorsConfiguration';
import { GenericServerError, InvoicesEmptyError } from '../../../../../../../../../Utils/StaticMessages/GenericErrorMessages';
import { GenericDownloadEmptyToast } from '../../../../../../../../../Utils/StaticMessages/ToastMessages';

const Summary = ({ setSummaryPopup, summaryPopup, tabName, setTabName, filterType, setFilterType, customerCode, invoiceDateTo, setInvoiceDateTo, invoiceDateFrom, setInvoiceDateFrom, Logout, serviceData }) => {

    const date = new Date();                                                    // for js date time function
    const location = useLocation();                                             // to use Location function from react router dom    

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                        // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                  // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                                 // getTableData() Status 
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Invoice No.", "Service",                                 // table headers used to make object of react data table component headers
        "Summary No.", "Invoice Date", "Due Date", "Amount", "Balance Amount", "Status"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [total, setTotal] = useState(null);                                   // for displaying total number in center of piechart
    const [pieChartData, setPieChartData] = useState([null])                    // for storing piechart data from api

    // Get Table Data Function Parameters
    const [serviceId, setServiceId] = useState(null);                                                                       // getTableData() service id
    const [dueDateFrom, setDueDateFrom] = useState(searchParams.get('dueDateFrom') || null);                                // getTableData() due from date
    const [dueDateTo, setDueDateTo] = useState(searchParams.get('dueDateTo') || null);                                      // getTableData() due to date
    const [sortCol, setSortCol] = useState("invoiceNumber");                                                                // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                                                         // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [customerId, setCustomerId] = useState(searchParams.get('customerId') || null);                                   // getTableData() customer id

    const [isDescInvoiceDate, setIsDescInvoiceDate] = useState(searchParams.get('sortCol') === "invoiceDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                   // sort state check for invoice date column
    const [isDescDueDate, setIsDescDueDate] = useState(searchParams.get('sortCol') === "dueDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                           // sort state check for due date column
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown
    const [customerData, setCustomerData] = useState(null);                           // data in customer filter

    const [serverErrorCloudInvoice, setServerErrorCloudInvoice] = useState(false);    // server error check state in table during api call to load data
    const [emptyCloudInvoice, setEmptyCloudInvoice] = useState(false);                // empty table check state in table during api call to load data
    const [emptyCloudInvoiceFilters, setEmptyCloudInvoiceFilters] = useState(false);  // empty filter response check state in table during api call to load data
    const [emptyCloudInvoiceSearch, setEmptyCloudInvoiceSearch] = useState(false);    // empty search response check state in table during api call to load data
    const [customerName, setCustomerName] = useState(location.state?.customerName ? location.state?.customerName : "");

    // filters Usestate
    const [invoiceServiceLastUsedDate, setInvoiceServiceLastUsedDate]
        = useState("default");                                                        // service dropdown saved data after applying filters
    const [invoiceDateLastUsedDate, setInvoiceDateLastUsedDate]
        = useState("default");                                                        // invoice date dropdown saved data after applying filters
    const [dueDateLastUsedDate, setDueDateLastUsedDate]
        = useState("default");                                                        // due date dropdown saved data after applying filters 
    const [statusLastUsedDate, setStatusLastUsedDate]
        = useState("default");                                                        // status dropdown saved data after applying filters  

    // if custom was selected in due date and invoice date in filter box
    const [invoiceDateCustomFromLast, setInvoiceDateCustomFromLast]
        = useState(date.toISOString().slice(0, 10));                                  // invoice from date saved data after applying filter having custom selection
    const [invoiceDateCustomToLast, setInvoiceDateCustomToLast]
        = useState(date.toISOString().slice(0, 10));                                  // invoice to date saved data after applying filter having custom selection
    const [dueDateCustomFromLast, setDueDateCustomFromLast]
        = useState(date.toISOString().slice(0, 10));                                  // due from date saved data after applying filter having custom selection
    const [dueDateCustomToLast, setDueDateCustomToLast]
        = useState(date.toISOString().slice(0, 10));                                  // due from date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                          // toast message displaying success message
    const [toastError, setToastError] = useState(false);                              // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                             // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 14,
    }));

    function PieCenterLabel({ children }) {
        const { width, height, left, top } = useDrawingArea();

        // Convert children to string
        const textContent = children.toString();
        const [labelWithCommas, numberWithCommas] = textContent.split(' ');

        // Remove commas from label
        const label = labelWithCommas.replace(/,/g, '');

        // Format number with commas
        const number = isNaN(parseInt(numberWithCommas.replace(/,/g, ''), 10)) ? '' : parseInt(numberWithCommas.replace(/,/g, ''), 10).toLocaleString();

        return (
            <StyledText x={left + width / 2} y={top + height / 2}>
                <tspan className="text-large" fill="grey">{label}</tspan>
                <tspan className="text-strong text-extra-large" fill={WIDGETS_LABEL_FILL_COLOR} x={left + width / 2} dy="1.2em">{number}</tspan>
            </StyledText>
        );
    }

    // Function to call all Customer count data
    async function GetOutstandingPayInvoices() {
        await api.get(`${CUSTOMER_DASHBOARD_UNPAID_INVOICES}/${customerCode}?duration=${filterType}`, config)
            .then(resp => {
                setPieChartData(resp.data);
                // Calculate the total sum
                let initialTotal = 0;
                resp.data.map((item) => {
                    initialTotal += item.value;
                });
                setTotal(initialTotal);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(UNPAID_INVOICE_STATUS, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?duration=${filterType}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage(GenericDownloadEmptyToast);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
        (invoiceDateFrom === undefined || invoiceDateFrom === null || invoiceDateFrom === "null") ? invoiceDateFrom = "" : invoiceDateFrom = invoiceDateFrom;
        (invoiceDateTo === undefined || invoiceDateTo === null || invoiceDateTo === "null") ? invoiceDateTo = "" : invoiceDateTo = invoiceDateTo;
        (dueDateFrom === undefined || dueDateFrom === null || dueDateFrom === "null") ? dueDateFrom = "" : dueDateFrom = dueDateFrom;
        (dueDateTo === undefined || dueDateTo === null || dueDateTo === "null") ? dueDateTo = "" : dueDateTo = dueDateTo;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        (status === undefined || status === null || status === "null") ? status = "OVERDUE,OUTSTANDING" : status = status;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${CLOUD_INVOICE_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&invoiceNumber=${search}&serviceId=${serviceId}&customerId=${customerCode}&status=${status}&startInvoiceDate=${invoiceDateFrom}&endInvoiceDate=${invoiceDateTo}&fromDueDate=${dueDateFrom}&toDueDate=${dueDateTo}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);

                if (resp.status == 204) {
                    if (search === "" && serviceId === "" && dueDateFrom === "" && dueDateTo === "") {
                        setEmptyCloudInvoice(true);
                    }
                    else if ((serviceId != "" || dueDateFrom != "" || dueDateTo != "") && isFilter) {
                        setEmptyCloudInvoiceFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyCloudInvoiceSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorCloudInvoice(false);
                    setEmptyCloudInvoice(false);
                    setEmptyCloudInvoiceFilters(false);
                    setEmptyCloudInvoiceSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Invoice No.": td.invoiceNumber,
                                    "Service": td.serviceName,
                                    "Summary No.": td.summaryNumber,
                                    "Invoice Date": td.invoiceDate,
                                    "Due Date": td.dueDate,
                                    "Amount": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                                    "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount)).toFixed(2))}`,
                                    "Status": td.status === 'PAID' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-paid"></span>
                                            <div className="py-1 text-muted text-small">Paid</div>
                                        </div>
                                    ) : td.status === 'OUTSTANDING' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-outstanding"></span>
                                            <div className="py-1 text-muted text-small">Outstanding</div>
                                        </div>
                                    ) : td.status === 'OVERDUE' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-overdue"></span>
                                            <div className="py-1 text-muted text-small">Overdue</div>
                                        </div>
                                    ) :
                                        (
                                            td.status
                                        ),
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Invoice Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescInvoiceDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("invoiceDate"); setIsDescDueDate(null); }}></span></>}
                                    {isDescInvoiceDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescInvoiceDate(false); setSortCol("invoiceDate"); }}></span></>}
                                    {isDescInvoiceDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("invoiceDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Due Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescDueDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); setIsDescInvoiceDate(null); }}></span></>}
                                    {isDescDueDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescDueDate(false); setSortCol("dueDate"); }}></span></>}
                                    {isDescDueDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Service") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1,
                            })
                        }
                        else if (td === "Amount" || td === "Balance Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 0.5,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCloudInvoice(true);
                }
            });
    };

    // React Data Table Custom Theme
    createTheme('solarized', REACT_DATA_TABLE_COLOR_THEME);

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: BACKGROUND_COLOR_SECONDARY,
                fontWeight: '500',
                fontSize: '0.875rem',
                color: `${TEXT_SECONDARY} !important`,
            },
        },
    };

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        if (eventKey === "This Month") {
            var endDateMonth = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            setInvoiceDateFrom(fromDate);
            setInvoiceDateTo(toDate);     // From and to Date will be same
        }
        else if (eventKey === "Last Month") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            setInvoiceDateFrom(fromDate);
            setInvoiceDateTo(toDate);     // From and to Date will be same
        }
        else {
            let lastQuarterEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
            let lastQuarterStartDate = new Date(date);
            lastQuarterStartDate.setMonth(date.getMonth() - 3);
            lastQuarterStartDate.setDate(1);

            let fromDate = `${String(lastQuarterStartDate.getDate()).padStart(2, '0')}/${String(lastQuarterStartDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterStartDate.getFullYear()}`;
            let toDate = `${String(lastQuarterEndDate.getDate()).padStart(2, '0')}/${String(lastQuarterEndDate.getMonth() + 1).padStart(2, "0")}/${lastQuarterEndDate.getFullYear()}`;
            setInvoiceDateFrom(fromDate);
            setInvoiceDateTo(toDate);
        }
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status, false, true))();
            return newPageNumber;
        });
    }

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
    }, [isDescInvoiceDate, isDescDueDate])

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTableFilterApplied(false);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        GetAllStatuses();
        GetAllCustomers();
    }, []);

    useEffect(() => {
        if (filterType) {
            GetOutstandingPayInvoices();
            setScrollToDivTop(true);
            setPageNo(1);
            if (tableFilterApplied)
                GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status, true);
            else
                GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status, customerId, false);
        }
    }, [filterType]);

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCloudInvoice(false); setEmptyCloudInvoice(false); setEmptyCloudInvoiceFilters(false); setEmptyCloudInvoiceSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Unpaid Invoices Summary</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color={TEXT_SECONDARY}
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor={TOAST_SUCCESS_ICON_COLOR} toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor={TOAST_ERROR_ICON_COLOR} toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills" defaultActiveKey={null}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={() => { DownloadAction(CUSTOMER_DASHBOARD_UNPAID_INVOICES_DOWNLOAD_REPORT + (customerCode), `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${customerName}_unpaid-invoices-summary.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div>
                                                        <Row>
                                                            <Col md={10}></Col>
                                                            <Col md={2}>
                                                                <Form.Group >
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown dropdown-specialclass ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item eventKey="This Month"
                                                                            className={filterType === "This Month" ? "selected" : ""}
                                                                        >This Month</NavDropdown.Item>
                                                                        <NavDropdown.Item eventKey="Last Month"
                                                                            className={filterType === "Last Month" ? "selected" : ""}
                                                                        >Last Month</NavDropdown.Item>
                                                                        <NavDropdown.Item eventKey="Last Quarter"
                                                                            className={filterType === "Last Quarter" ? "selected" : ""}
                                                                        >Last Quarter</NavDropdown.Item>  </NavDropdown>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className={`${styles['bar-chart-height']}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <PieChart
                                                                height={300}
                                                                series={[{ data: pieChartData, innerRadius: 110 }]}
                                                                colors={[WIDGETS_PRIMARY_COLOR, WIDGETS_SECONDARY_COLOR]}
                                                                slotProps={{
                                                                    legend: {
                                                                        direction: 'row',
                                                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                                                        padding: 0,
                                                                        labelStyle: {
                                                                            fill: 'grey',
                                                                        },
                                                                    },
                                                                    popper: {
                                                                        sx: {
                                                                            zIndex: 99999,
                                                                        },
                                                                    },
                                                                }}
                                                                margin={{ top: 0, bottom: 40, left: 80 }}
                                                            >
                                                                {pieChartData.length > 0 && <PieCenterLabel>Unpaid&nbsp;Invoices {pieChartData && total}</PieCenterLabel>}
                                                            </PieChart>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row >
                                                            <Col md={3}>
                                                                <h2 className='mx-7'>Invoices {!emptyCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            {/* desktop and tab search and filter */}
                                                            <Col className="px-1 d-none d-md-block mb-2" md={7}>
                                                                <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCloudInvoice && !emptyCloudInvoice && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCloudInvoiceFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCloudInvoiceFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }

                                                                        {!filterFlag && !emptyCloudInvoiceSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCloudInvoiceSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                    </Col>}
                                                                    {
                                                                        !emptyCloudInvoiceSearch && showFilterBox &&
                                                                        <SummaryFilterBox
                                                                            serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData} pageNo={pageNo}
                                                                            pageSize={pageSize} searchValueTable={searchValueTable} invoiceDateFrom={invoiceDateFrom} invoiceDateTo={invoiceDateTo}
                                                                            setInvoiceDateFrom={setInvoiceDateFrom} setInvoiceDateTo={setInvoiceDateTo} dueDateFrom={dueDateFrom}
                                                                            setDueDateFrom={setDueDateFrom} dueDateTo={dueDateTo} setDueDateTo={setDueDateTo} sortCol={sortCol} sortDir={sortDir}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} invoiceServiceLastUsedDate={invoiceServiceLastUsedDate}
                                                                            setInvoiceServiceLastUsedDate={setInvoiceServiceLastUsedDate} invoiceDateLastUsedDate={invoiceDateLastUsedDate} status={status} setStatus={setStatus}
                                                                            setInvoiceDateLastUsedDate={setInvoiceDateLastUsedDate} dueDateLastUsedDate={dueDateLastUsedDate} setDueDateLastUsedDate={setDueDateLastUsedDate}
                                                                            invoiceDateCustomFromLast={invoiceDateCustomFromLast} setInvoiceDateCustomFromLast={setInvoiceDateCustomFromLast} invoiceDateCustomToLast={invoiceDateCustomToLast}
                                                                            setInvoiceDateCustomToLast={setInvoiceDateCustomToLast} dueDateCustomFromLast={dueDateCustomFromLast} setDueDateCustomFromLast={setDueDateCustomFromLast}
                                                                            dueDateCustomToLast={dueDateCustomToLast} setDueDateCustomToLast={setDueDateCustomToLast}
                                                                            statusLastUsedDate={statusLastUsedDate} setStatusLastUsedDate={setStatusLastUsedDate} customerId={customerId} setCustomerId={setCustomerId}
                                                                            serviceData={serviceData} statusData={statusData} setPageNo={setPageNo} customerData={customerData} setCustomerData={setCustomerData}
                                                                            setScrollToDivTop={setScrollToDivTop} setTableFilterApplied={setTableFilterApplied}
                                                                        />
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown-2" className={`servcies-dropdown dropdown-specialclass`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item eventKey="This Month"
                                                                            className={filterType === "This Month" ? "selected" : ""}
                                                                        >This Month</NavDropdown.Item>
                                                                        <NavDropdown.Item eventKey="Last Month"
                                                                            className={filterType === "Last Month" ? "selected" : ""}
                                                                        >Last Month</NavDropdown.Item>
                                                                        <NavDropdown.Item eventKey="Last Quarter"
                                                                            className={filterType === "Last Quarter" ? "selected" : ""}
                                                                        >Last Quarter</NavDropdown.Item>
                                                                    </NavDropdown>
                                                                </Form.Group></Col>
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{GenericServerError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{InvoicesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{InvoicesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">{InvoicesEmptyError}</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoice && !emptyCloudInvoiceSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCloudInvoice}
                                                                                emptyUser={emptyCloudInvoice}
                                                                                emptyUserFilters={emptyCloudInvoiceFilters}
                                                                                emptyUserSearch={emptyCloudInvoiceSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col md={10}>
                                                                <p className='mx-2 notes-confirmation'><a href={localStorage.getItem("b2b_navigation") + "Invoice-Payment/"} target='_blank'>Click here</a> to pay invoices.</p>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className="btn-wrapper text-right">
                                                                    <Button
                                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                        variant="light"
                                                                        onClick={() => {
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary