import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';

const AuditFilterBox = (props) => {
    const [auditDateCustomCheck, setAuditDateCustomCheck] = useState(false);          // check state for custom chosen in invoice date 
    const date = new Date();                                                          // for js date time function
    const navigate = useNavigate();                                                   // navigating to new page
    const { instance } = useMsal();                                                   // logging purpose 

    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedActionTypes, setSelectedActionTypes] = useState([]);

    // Function to handle "All Action Types" checkbox
    const handleAllActionTypesChange = (isChecked) => {
        setSelectedActionTypes(isChecked ? props.actionTypeData.map((actionType) => actionType.id) : []);
    };

    // Function to update selected account types
    const handleActionTypeChange = (id) => {
        // console.log("Data: ", (props.actionTypeId && (props.actionTypeId).includes(id)), (selectedActionTypes).includes(id))
        setSelectedActionTypes((prevSelectedActionTypes) => {
            if (prevSelectedActionTypes.includes(id)) {
                return prevSelectedActionTypes.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedActionTypes, id];
            }
        });
    };

    // Function to update selected statuses
    const handleStatusChange = (id) => {
        setSelectedStatuses((prevSelectedStatuses) => {
            if (prevSelectedStatuses.includes(id)) {
                return prevSelectedStatuses.filter((statusId) => statusId !== id);
            } else {
                return [...prevSelectedStatuses, id];
            }
        });
    };

    // Function to handle "All Statuses" checkbox
    const handleAllStatusesChange = (isChecked) => {
        setSelectedStatuses(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            // console.log(fromDate);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            // console.log(fromDate);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
            // console.log(fromDate,toDate);
        }
        else if (e.target.value === "custom") {
            dateCheck(true);

        }
        else {
            from(null);
            to(null);
        }
    }

    const [searchPlanValue, setSearchPlanValue] = useState(""); // Searched Value from Plan
    const clearFilterSearchPlan = () => { // Clear Filter Box Search Input
        setSearchPlanValue("");
    }

    const [searchCustomerValue, setSearchCustomerValue] = useState(""); // Searched Value from Customer
    const clearFilterSearchCustomer = () => { // Clear Filter Box Search Input
        setSearchCustomerValue("");
    }

    // Date Array
    const dateArray = [
        {
            id: "0",
            value: "today",
            name: "Today"
        },
        {
            id: "1",
            value: "yesterday",
            name: "Yesterday"
        },
        {
            id: "2",
            value: "last7",
            name: "Last 7 Days"
        },
        {
            id: "3",
            value: "last30",
            name: "Last 30 Days"
        },
        {
            id: "4",
            value: "thismonth",
            name: "This Month"
        },
        {
            id: "5",
            value: "lastmonth",
            name: "Last Month"
        },
        {
            id: "6",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props.planId && props.planId !== null) {
            const element = document.getElementById(props.planId);
            if (element) {
                element.checked = false;
            }
        };

        if (props.customerId && props.customerId !== null) {
            const element = document.getElementById(props.customerId);
            if (element) {
                element.checked = false;
            }
        };
        if (props.status && props.status !== null) {
            const element = document.getElementById(props.status);
            if (element) {
                element.checked = false;
            }
        };

        if (props.auditDateLastUsedDate !== "default") {
            props.setAuditDateLastUsedDate("default");
            document.getElementById(props.auditDateLastUsedDate).checked = false;
        }
        props.setScrollToDivTop(true);
        setSelectedActionTypes([]);
        setSelectedStatuses([]);
        props.setPlanId(null);
        props.setActionTypeId();
        props.setCustomerId(null);
        props.setAuditDateFrom(null);
        props.setAuditDateTo(null);
        props.setStatus([]);
        setAuditDateCustomCheck(false);
        props.setAuditDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setAuditDateCustomToLast(date.toISOString().slice(0, 10));
        props.GetTableData(null, props.pageNo, props.pageSize, props.searchValueTable, null, null, null, null, [], null, []);
        props.setFilterFlag(false);
    }

    useEffect(() => {
        (props.actionTypeId != null || props.actionTypeId != undefined) ? setSelectedActionTypes(props.actionTypeId) : setSelectedActionTypes([]);
        (props.status != null || props.status != undefined) ? setSelectedStatuses(props.status) : setSelectedStatuses([]);
    }, []);

    return (
        <div className="filter-box">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">


                    <Col className="filter-column">
                        <h3 className="mb-3">Customer Name</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchCustomerValue}
                                    onChange={(e) => setSearchCustomerValue(e.target.value)}
                                />
                                <InputGroup.Text className="clear-search" onClick={clearFilterSearchCustomer}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">
                                {
                                    props.customerData && searchCustomerValue.length>0 &&
                                    props.customerData.filter(option => (option.name).toLowerCase().includes(searchCustomerValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.id}
                                                    type={'radio'}
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="customerGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.customerId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setCustomerId("") : props.setCustomerId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    props.customerData && searchCustomerValue.length===0 && 
                                    props.customerData.slice(0,100).filter(option => (option.name).toLowerCase().includes(searchCustomerValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.id}
                                                    type={'radio'}
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="customerGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.customerId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setCustomerId("") : props.setCustomerId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    !props.customerData && <>Loading...</>
                                }

                            </div>
                        </div>
                    </Col>


                    <Col className="filter-column">
                        <h3 className="mb-3">Plan</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchPlanValue}
                                    onChange={(e) => setSearchPlanValue(e.target.value)}
                                />
                                <InputGroup.Text className="clear-search" onClick={clearFilterSearchPlan}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    props.planData && searchPlanValue.length>0 &&
                                    props.planData.filter(option => (option.planName).toLowerCase().includes(searchPlanValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.productPlanId}
                                                    type={'radio'}
                                                    id={filteredOption.productPlanId}
                                                    label={filteredOption.planName}
                                                    name="plansGroup"
                                                    value={filteredOption.productPlanId}
                                                    aria-label={filteredOption.planName}
                                                    defaultChecked={filteredOption.productPlanId == props.planId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setPlanId(null) : props.setPlanId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })

                                }

{
                                    props.planData && searchPlanValue.length===0 &&
                                    props.planData.slice(0,100).filter(option => (option.planName).toLowerCase().includes(searchPlanValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.productPlanId}
                                                    type={'radio'}
                                                    id={filteredOption.productPlanId}
                                                    label={filteredOption.planName}
                                                    name="plansGroup"
                                                    value={filteredOption.productPlanId}
                                                    aria-label={filteredOption.planName}
                                                    defaultChecked={filteredOption.productPlanId == props.planId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setPlanId(null) : props.setPlanId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })

                                }

                                {
                                    !props.planData && <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col className="filter-column">
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.statusData && props.statusData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`status-all`}
                                        label="All Statuses"
                                        checked={(selectedStatuses.length === props.statusData.length)}
                                        onChange={(e) => handleAllStatusesChange(e.target.checked)}
                                    />}
                                    {props.statusData && props.statusData.length > 0 &&
                                        props.statusData.map((statusItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="checkbox"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatuses.includes(statusItem.id)}
                                                    onChange={() => handleStatusChange(statusItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>

                    </Col>

                    <Col className="filter-column">
                        <h3 className="mb-3">Action Type</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.actionTypeData && props.actionTypeData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`account-all`}
                                        label="All Account Types"
                                        checked={(selectedActionTypes.length === props.actionTypeData.length)}
                                        onChange={(e) => handleAllActionTypesChange(e.target.checked)}
                                    />}
                                    {props.actionTypeData && props.actionTypeData.length > 0 &&
                                        props.actionTypeData.map((account) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={account.id}
                                                    type="checkbox"
                                                    id={`account-${account.id}`}
                                                    label={account.name}
                                                    checked={selectedActionTypes.includes(account.id)}
                                                    onChange={() => handleActionTypeChange(account.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>


                    </Col>

                    <Col className="filter-column">
                        <h3 className="mb-3">Date</h3>
                        <div>

                            <Form>
                                <div className="filter-options date-filter-options">
                                    {
                                        dateArray.map(auditDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={auditDate.value}
                                                        type={'radio'}
                                                        id={auditDate.value}
                                                        label={auditDate.name}
                                                        name="auditDateGroup"
                                                        value={auditDate.value}
                                                        aria-label={auditDate.name}
                                                        defaultChecked={auditDate.value == props.auditDateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setAuditDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setAuditDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }
                                                            DatePicker(e, props.setAuditDateFrom, props.setAuditDateTo, setAuditDateCustomCheck); props.setAuditDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )

                                        })
                                    }


                                    {
                                        (auditDateCustomCheck || props.auditDateLastUsedDate === "custom") &&
                                        <>
                                            <p className='mt-2'>From :</p>
                                            <Form.Control value={props.auditDateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setAuditDateCustomFromLast(null); props.setAuditDateFrom(null); } else { props.setAuditDateCustomFromLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setAuditDateFrom(String(date[0] + "/" + date[1] + "/" + date[2])); } }} />
                                            <p className='mt-2'>To :</p>
                                            <Form.Control value={props.auditDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setAuditDateCustomToLast(null); props.setAuditDateTo(null); } else { props.setAuditDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setAuditDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                        </>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.planId && !props.auditDateFrom && !props.auditDateTo && !props.customerId && (props.status && props.status.length == 0) && selectedStatuses.length == 0 && selectedActionTypes.length == 0 && !props.actionTypeId) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.planId || props.auditDateFrom || props.auditDateTo || props.customerId || (props.status && props.status.length > 0) || selectedStatuses.length > 0 || selectedActionTypes.length > 0 || props.actionTypeId) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.planId || props.auditDateFrom || props.auditDateTo || props.customerId || (props.status && props.status.length > 0)  || selectedStatuses.length > 0 || selectedActionTypes.length > 0 || props.actionTypeId) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    // console.log('Selected Statuses:', selectedStatuses);
                                    // console.log('Selected Account Types:', selectedActionTypes);
                                    props.setScrollToDivTop(true);
                                    props.setActionTypeId(selectedActionTypes);
                                    props.setStatus(selectedStatuses);
                                    props.setPageNo(1);
                                    props.GetTableData(props.customerId, 1, props.pageSize, props.searchValueTable, props.auditDateFrom, props.auditDateTo, null, null, selectedStatuses, props.planId, selectedActionTypes,false, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.planId && !props.auditDateFrom && !props.auditDateTo && !props.customerId && (props.status && props.status.length == 0) && selectedStatuses.length == 0 && selectedActionTypes.length == 0 && !props.actionTypeId) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default AuditFilterBox;