import React from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap';



const VendorDetailsFAQs = ({vendorDetailsData}) => {
    let i=0;
    return (
        <>
            <Container className="vendor-faq-block" fluid>
                <Container fluid>
                    <Row>
                        <h2 className='mb-5'>Frequently asked questions</h2>
                        <Col lg={8} className='mx-auto'>
                            <div className="accordion-panel">
                                <Accordion className='mb-5'>
                                    {
                                        
                                        vendorDetailsData && vendorDetailsData.fAQ.contentItems.map((data)=>{
                                            return(
                                                <Accordion.Item eventKey={i++} className="faqs-accordion">
                                                    <Accordion.Header>{data.question}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul>
                                                            {
                                                                data.answer && data.answer.contentItems.map((subData)=>{
                                                                    return(
                                                                        <li dangerouslySetInnerHTML={{__html:subData.value}}></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default VendorDetailsFAQs;