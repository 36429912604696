import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from './SummaryFilterBox.module.scss'

const SummaryFilterBox = (props) => {
    const [invoiceDateCustomCheck, setInvoiceDateCustomCheck] = useState(false);      // check state for custom chosen in invoice date
    const [dueDateCustomCheck, setDueDateCustomCheck] = useState(false);              // check state for custom chosen in due date  
    const date = new Date();                                                          // for js date time function
    const [selectedStatus, setSelectedStatus] = useState([]);                         // all the selected value in status

    // Function to handle "All Status" checkbox
    const handleAllStatusChange = (isChecked) => {
        setSelectedStatus(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "custom") {
            dateCheck(true);
        }
        else {
            from(null);
            to(null);
        }
    }

    const [searchServiceValue, setSearchServiceValue] = useState(""); // Searched Value from Service
    const clearFilterSearchService = () => { // Clear Filter Box Search Input
        setSearchServiceValue("");
    }

    // Date Array
    const dateArray = [
        {
            id: "0",
            value: "today",
            name: "Today"
        },
        {
            id: "1",
            value: "yesterday",
            name: "Yesterday"
        },
        {
            id: "2",
            value: "last7",
            name: "Last 7 Days"
        },
        {
            id: "3",
            value: "last30",
            name: "Last 30 Days"
        },
        {
            id: "4",
            value: "thismonth",
            name: "This Month"
        },
        {
            id: "5",
            value: "lastmonth",
            name: "Last Month"
        },
        {
            id: "6",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props.status && props.status !== null) {
            const element = document.getElementById(props.status);
            if (element) {
                element.checked = false;
            }
        };
        if (props.customerId !== null) {
            document.getElementById(props.customerId).checked = false;
        };
        if (props.serviceId && props.serviceId !== null) {
            const element = document.getElementById(props.serviceId);
            if (element) {
                element.checked = false;
            }
        };
        if (props.invoiceDateLastUsedDate !== "default") {
            props.setInvoiceDateLastUsedDate("default");
            document.getElementById(props.invoiceDateLastUsedDate).checked = false;
        }

        if (props.dueDateLastUsedDate !== "default") {
            props.setDueDateLastUsedDate("default");
            document.getElementById("due_date_" + props.dueDateLastUsedDate).checked = false;
        }

        setSelectedStatus([]);
        props.setScrollToDivTop(true);
        props.setTableFilterApplied(false);
        props.setCustomerId(null);
        props.setServiceId(null);
        props.setInvoiceDateFrom(null);
        props.setInvoiceDateTo(null);
        props.setDueDateFrom(null);
        props.setDueDateTo(null);
        props.setStatus(null);
        setInvoiceDateCustomCheck(false);
        setDueDateCustomCheck(false);
        props.setDueDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setDueDateCustomToLast(date.toISOString().slice(0, 10));
        props.setInvoiceDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setInvoiceDateCustomToLast(date.toISOString().slice(0, 10));
        props.setPageNo(1)
        props.GetTableData(1, props.pageSize, props.searchValueTable, null, null, null, null, null, props.sortCol, props.sortDir, null, null);
        props.setFilterFlag(false);
    }

    useEffect(() => {
        (props.status != null || props.status != undefined) ? setSelectedStatus(props.status) : setSelectedStatus([]);
    }, []);

    return (
        <div className="filter-box filter-box-widget dashboard-filters">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className={`mb-3 mobile-filter-row ${styles['ipad-pro-filterbox']}`}>
                    <Col className="filter-column" lg={3} md={3}>
                        <h3 className="mb-3">Service</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchServiceValue}
                                    onChange={(e) => setSearchServiceValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchService}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    props.serviceData &&
                                    props.serviceData.filter(option => (option.name).toLowerCase().includes(searchServiceValue.toLowerCase())).map((filteredOption) => {
                                        if (filteredOption.serviceType == "ONLINE") {
                                            return (
                                                <>
                                                    <Form.Check // prettier-ignore
                                                        key={filteredOption.id}
                                                        type={'radio'}
                                                        id={filteredOption.id}
                                                        label={filteredOption.name}
                                                        name="servicesGroup"
                                                        value={filteredOption.id}
                                                        aria-label={filteredOption.name}
                                                        defaultChecked={filteredOption.id == props.serviceId ? true : false}
                                                        onChange={(e) => {
                                                            (e.target.value === "default") ? props.setServiceId(null) : props.setServiceId(e.target.id);
                                                        }
                                                        }
                                                    />
                                                </>
                                            )
                                        }
                                    })
                                }

                                {
                                    !props.serviceData && <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col className="filter-column" lg={3} md={3}>
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.statusData && props.statusData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="radio"
                                        id={`statuses-all`}
                                        label="All Statuses"
                                        checked={(selectedStatus.length === props.statusData.length)}
                                        onChange={(e) => handleAllStatusChange(e.target.checked)}
                                    />}
                                    {props.statusData && props.statusData.length > 0 &&
                                        props.statusData.map((statusItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="radio"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatus.includes(statusItem.id) && !((selectedStatus.length === props.statusData.length))}
                                                    onChange={() => setSelectedStatus(statusItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>
                    </Col>
                    <Col className="filter-column" lg={3} md={3}>
                        <h3 className="mb-3">Invoice Date</h3>
                        <div>

                            <Form>
                                <div className="filter-options date-filter-options">
                                    {
                                        dateArray.map(invoiceDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={invoiceDate.value}
                                                        type={'radio'}
                                                        id={invoiceDate.value}
                                                        label={invoiceDate.name}
                                                        name="invoiceDateGroup"
                                                        value={invoiceDate.value}
                                                        aria-label={invoiceDate.name}
                                                        defaultChecked={invoiceDate.value == props.invoiceDateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setInvoiceDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setInvoiceDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }
                                                            DatePicker(e, props.setInvoiceDateFrom, props.setInvoiceDateTo, setInvoiceDateCustomCheck); props.setInvoiceDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )

                                        })
                                    }

                                    {
                                        (invoiceDateCustomCheck || props.invoiceDateLastUsedDate === "custom") &&
                                        <>
                                            <p className='mt-2'>From :</p>
                                            <Form.Control value={props.invoiceDateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setInvoiceDateCustomFromLast(null); props.setInvoiceDateFrom(null); } else { props.setInvoiceDateCustomFromLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setInvoiceDateFrom(String(date[0] + "/" + date[1] + "/" + date[2])); } }} />
                                            <p className='mt-2'>To :</p>
                                            <Form.Control value={props.invoiceDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setInvoiceDateCustomToLast(null); props.setInvoiceDateTo(null); } else { props.setInvoiceDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setInvoiceDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                        </>
                                    }
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col className="filter-column" lg={3} md={3}>
                        <h3 className="mb-3">Due Date</h3>
                        <div>
                            {
                                <Form>
                                    <div className="filter-options date-filter-options">
                                        {
                                            dateArray.map(dueDate => {
                                                return (
                                                    <>
                                                        < Form.Check // prettier-ignore
                                                            key={dueDate.value}
                                                            type={'radio'}
                                                            id={"due_date_" + dueDate.value}
                                                            label={dueDate.name}
                                                            name="dueDateGroup"
                                                            value={dueDate.value}
                                                            aria-label={dueDate.id}
                                                            defaultChecked={dueDate.value == props.dueDateLastUsedDate ? true : false}
                                                            onChange={(e) => {
                                                                if (e.target.value === "custom") {
                                                                    props.setDueDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                    props.setDueDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                                }
                                                                DatePicker(e, props.setDueDateFrom, props.setDueDateTo, setDueDateCustomCheck); props.setDueDateLastUsedDate(e.target.value);
                                                            }}
                                                        />
                                                    </>
                                                )

                                            })
                                        }
                                        {
                                            (dueDateCustomCheck || props.dueDateLastUsedDate === "custom") &&
                                            <>
                                                <p className='mt-2'>From :</p>
                                                <Form.Control value={props.dueDateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setDueDateCustomFromLast(null); props.setDueDateFrom(null); } else { props.setDueDateCustomFromLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setDueDateFrom(String(date[0] + "/" + date[1] + "/" + date[2])); } }} />
                                                <p className='mt-2'>To :</p>
                                                <Form.Control value={props.dueDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setDueDateCustomToLast(null); props.setDueDateTo(null); } else { props.setDueDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setDueDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                            </>
                                        }
                                    </div>
                                </Form>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.serviceId && !props.invoiceDateFrom && !props.invoiceDateTo && !props.customerId && !props.dueDateFrom && !props.dueDateTo && !props.status && selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.serviceId || props.invoiceDateFrom || props.invoiceDateTo || props.customerId || props.dueDateFrom || props.dueDateTo || props.status || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.serviceId || props.invoiceDateFrom || props.invoiceDateTo || props.dueDateFrom || props.customerId || props.dueDateTo || props.status || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setPageNo(1);
                                    props.setScrollToDivTop(true);
                                    props.setTableFilterApplied(true);
                                    props.setStatus(selectedStatus);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.serviceId, props.invoiceDateFrom, props.invoiceDateTo, props.dueDateFrom, props.dueDateTo, props.sortCol, props.sortDir, props.customerId, selectedStatus, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.serviceId && !props.invoiceDateFrom && !props.invoiceDateTo && !props.dueDateFrom && !props.dueDateTo && !props.customerId && !props.status && selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default SummaryFilterBox