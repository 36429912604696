import React, { useRef, useState, useEffect, useContext } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import "./vendorDetails..scss";
import synnexLogoUpdated from '../../../../Assets/Images/Illustrations/Offline Services/synnex-logo-dark-updated.png';
import ScrollToTop from '../../../../GlobalComponents/ScrollToTop';
import VendorContactUs from './LocalComponents/ContactUsBlock';
import VendorDetailsFAQs from './LocalComponents/VendorFAQsBlock';
import { LANDING_PAGE_CMS_DATA } from '../../../../Utils/GlobalConstants';
import { cmsApi } from '../../../../Utils/API';
import { useLocation, useNavigate } from 'react-router-dom';
import CmsDetailsContext from '../../../../Context/CmsDetails/CmsDetailsContext';

const VendorDetails = () => {
    const contactUsRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const globalData = useContext(CmsDetailsContext);
    const searchParams = new URLSearchParams(location.search);
    const [vendorDetailsData, setVendorDetailsData] = useState(null);                               // vendor details page data
    const [serviceName, setServiceName] = useState(searchParams.get('serviceName') || null);        // sevice name using search params

    // Function for calling Landing Page item data from Orchid Core CMS
    async function GetVendorDetailsPageData() {
    // console.log("Vendor Details Data Fetching Initiated");
    const data = `{
  cloudServiceContent(where: {alias: {alias_in: "${serviceName}"}}) {
    block {
      contentItems {
        ... on CloudServiceContentBlock {
          title
          subTitle
          blockNumber
          subDescription {
            contentItems {
              ... on TextList {
                value
              }
            }
          }
          image {
            urls
            paths
          }
          contentList {
            contentItems {
              ... on TextList {
                value
              }
            }
          }
        }
      }
    }
    middleBlockContent {
      contentItems {
        ... on CloudServiceContentMiddleBlock {
          heading
          subHeading
          middleBlock {
            contentItems {
              ... on CloudServiceContentBlock {
                title
                blockNumber
                image {
                  urls
                  paths
                }
                contentList {
                  contentItems {
                    ... on TextList {
                      modifiedUtc
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    fAQ {
      contentItems {
        ... on CloudServiceFAQ {
          question
          answer {
            contentItems {
                ... on TextList {
                    value
                }
            }
          }
        }
      }
    }
  }
}`;

    // Defining Header Configuration
    const config = {
        headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
        },
    };

    // Axios Post Request
    await cmsApi
        .post(LANDING_PAGE_CMS_DATA, data, config)
        .then((resp) => {
        // handle success
        // console.log(resp.data.data);
        setVendorDetailsData(resp.data.data.cloudServiceContent[0]);
        })
        .catch((error) => {
        // handle error
        // console.log(error);
        });
    }

    // Scrolling to contact us form smoothly
    const scrollToContactUs = () => {
        if (contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Element #contactUs not found');
        }
    };

    useEffect(() => {
        if(serviceName){
            // console.log("serviceName :",serviceName);
            GetVendorDetailsPageData(); 
        }
        
    }, [serviceName])

    return (
        <>
            {vendorDetailsData && <div className="our-vendors-details-component">

                <Container className="vendor-logo-block" fluid>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <img src={synnexLogoUpdated} className="vendor-logo-block-synnex-image" />
                            </Col>

                        </Row>
                    </Container>
                </Container>
                {
                    vendorDetailsData.block.contentItems.map((data)=>{
                        if(data.blockNumber==1){
                            return(
                                <Container className="vendor-brand-block" fluid>
                                    <Container fluid>
                                        <Row className="py-3">
                                            <Col md={12} className="px-0 row">
                                                {/* <p className="gotoback" onClick={()=>navigate("/our-vendors")}>
                                                    <span>{data.title}</span>
                                                </p> */}
                                                <p className="gotoback">
                                                    <span>{data.title}</span>
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                            {
                                                        data?.contentList.contentItems.map((data)=>{
                                                            return(
                                                                <p>{data.value}</p>
                                                            )
                                                        })
                                                    }
                                                <Button className="px-4" onClick={scrollToContactUs}>Contact Us</Button>
                                            </Col>

                                            { }
                                            <Col md={6} className="text-center vendor-logo">
                                                <img src={globalData && (globalData.baseUrl+data.image.urls[0]) } />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            )
                        }
                        else if(data.blockNumber==2){
                            return(
                                <Container className="vendor-aboutus-block" fluid>
                                    <Container fluid>
                                        <Row className="py-3">
                                            <Col className='text-center d-flex align-items-center justify-content-center' md={5}>
                                                <img className='vendor-image-block' src={globalData && (globalData.baseUrl+data.image.urls[0]) } />
                                            </Col>

                                            <Col md={7} className=' d-flex align-items-center justify-content-center flex-column'>
                                                <p className='mb-0'>{data.subTitle}</p>
                                                <h2 className='w-100 py-2'>{data.title}</h2>
                                                {
                                                        data?.contentList.contentItems.map((data)=>{
                                                            return(
                                                                <p>{data.value}</p>
                                                            )
                                                        })
                                                    }
                                            </Col>

                                        </Row>
                                    </Container>
                                </Container>
                            )
                        }
                    })
                }

                {
                    vendorDetailsData.middleBlockContent.contentItems.map((data)=>{
                            return(
                                <Container className="vendor-services-block text-center" fluid>
                                    <Container fluid>
                                        <Row className="py-3">
                                            <Col md={12}>
                                                <h2>{data.heading}</h2>
                                                <p>{data.subHeading}</p>
                                            </Col>
                                            <Col md={12}>
                                                <Row className='d-flex align-items-center justify-content-center flex-row'>
                                                    {
                                                        data.middleBlock.contentItems.map((subData)=>{
                                                            return(
                                                                <Col md={3} className="vendor-service">
                                                                    <img src={globalData && (globalData.baseUrl+subData.image.urls[0]) } />
                                                                    <h3>{subData.title} </h3>
                                                                    {
                                                                        subData?.contentList.contentItems.map((data)=>{
                                                                            return(
                                                                                <p>{data.value}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>

                                        </Row>
                                    </Container>
                                </Container>
                            )
                    })
                }
                
                

                

                {
                    vendorDetailsData.block.contentItems.map((data)=>{
                        if(data.blockNumber==3){
                            return(
                                <Container className="vendor-business-block" fluid>
                                    <Container fluid>
                                        <Row className="py-3">
                                            <Col md={7} className='d-flex align-items-center justify-content-center flex-column'>
                                                <p className='mb-0'>{data.subTitle}</p>
                                                <h2 className='w-100 py-2'>{data.title}</h2>
                                                {
                                                        data?.contentList.contentItems.map((data)=>{
                                                            return(
                                                                <p>{data.value}</p>
                                                            )
                                                        })
                                                    }
                                            </Col>

                                            <Col className='text-center d-flex align-items-center justify-content-center flex-column' md={5}>
                                                <img className='vendor-image-block' src={globalData && (globalData.baseUrl+data.image.urls[0]) } />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            )
                        }
                        else if(data.blockNumber==4){
                            return(
                                <Container className="vendor-campaign-block" fluid>
                                    <Container fluid>
                                        <Row className="py-3">
                                            <Col className='text-center d-flex align-items-center justify-content-center' md={5}>
                                                <img className='vendor-image-block' src={globalData && (globalData.baseUrl+data.image.urls[0]) } />
                                            </Col>

                                            <Col md={7} className='d-flex align-items-center justify-content-center flex-column'>
                                                <p className='mb-0'>{data.subTitle}</p>
                                                <h2 className='w-100 py-2'>{data.title}</h2>
                                                {
                                                        data?.contentList.contentItems.map((data)=>{
                                                            return(
                                                                <p>{data.value}</p>
                                                            )
                                                        })
                                                    }
                                                <ul>
                                                    {
                                                        data.subDescription.contentItems.map((data)=>{
                                                            return(
                                                                <li>{data.value}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            )
                        }
                    })
                } 

                <VendorDetailsFAQs vendorDetailsData={vendorDetailsData} />

                <VendorContactUs vendorDetailsData={vendorDetailsData} contactUsRef={contactUsRef} />
            </div>}
            <ScrollToTop />
        </>
    )
}

export default VendorDetails;