import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from './orderHistoryFilterbox.module.scss';
import { GET_ORDER_TYPE_LIST } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';

const OrderHistoryFilterBox = (props) => {
    const [orderHistoryDateCustomCheck, setOrderHistoryDateCustomCheck] = useState(false);      // check state for custom chosen in order history date
    const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);                // all the selected value in order type
    const [selectedStatus, setSelectedStatus] = useState([]);        // all the selected value in status

    const date = new Date();                                                                   // for js date time function
    const [orderTypeData, setOrderTypeData] = useState(null);                                  // data in order type dropdown
    const [showOrderTypeDropdown, setShowOrderTypeDropdown] = useState(false);

    // Function to handle "All Status" checkbox
    const handleAllStatusChange = (isChecked) => {
        setSelectedStatus(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to handle "All order types" checkbox
    const handleAllOrderTypeChange = (isChecked) => {
        setSelectedOrderTypes(isChecked ? orderTypeData.map((orders) => orders.id) : []);
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in order types Section in Filter Box
    async function GetAllOrderType() {
        await api.get(GET_ORDER_TYPE_LIST, config)
            .then(resp => {
                setOrderTypeData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    }

    // Function to Convert Date Selection Options to from and to dates in DD/MM/YYYYY format
    function DatePicker(e, from, to, dateCheck) {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        dateCheck(false);
        if (e.target.value === "yesterday") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 1));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "today") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            from(fromDate);
            to(fromDate);     // From and to Date will be same
        }
        else if (e.target.value === "last7") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 7));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "last30") {
            var today = new Date();
            var yesterday = new Date(today.setDate(today.getDate() - 30));
            let fromDate = String(yesterday.getDate()).padStart(2, '0') + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "thismonth") {
            var endDateMonth = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            let fromDate = "01" + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            let toDate = String(endDateMonth.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + date.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "lastmonth") {
            var today = new Date();
            var yesterday = new Date(today.setMonth(today.getMonth() - 1));
            var lastDayOfMonth = new Date(yesterday.getFullYear(), yesterday.getMonth() + 1, 0);
            let fromDate = "01" + "/" + String(yesterday.getMonth() + 1).padStart(2, "0") + "/" + yesterday.getFullYear()
            let toDate = String(lastDayOfMonth.getDate()).padStart(2, '0') + "/" + String(lastDayOfMonth.getMonth() + 1).padStart(2, "0") + "/" + lastDayOfMonth.getFullYear()
            from(fromDate);
            to(toDate);     // From and to Date will be same
        }
        else if (e.target.value === "custom") {
            dateCheck(true);
        }
        else {
            from(null);
            to(null);
        }
    }


    const [searchServiceValue, setSearchServiceValue] = useState(""); // Searched Value from Service
    const clearFilterSearchService = () => { // Clear Filter Box Search Input
        setSearchServiceValue("");
    }

    const [searchCustomerValue, setSearchCustomerValue] = useState(""); // Searched Value from Customer
    const clearFilterSearchCustomer = () => { // Clear Filter Box Search Input
        setSearchCustomerValue("");
    }

    // Date Array
    const dateArray = [
        {
            id: "0",
            value: "today",
            name: "Today"
        },
        {
            id: "1",
            value: "yesterday",
            name: "Yesterday"
        },
        {
            id: "2",
            value: "last7",
            name: "Last 7 Days"
        },
        {
            id: "3",
            value: "last30",
            name: "Last 30 Days"
        },
        {
            id: "4",
            value: "thismonth",
            name: "This Month"
        },
        {
            id: "5",
            value: "lastmonth",
            name: "Last Month"
        },
        {
            id: "6",
            value: "custom",
            name: "Custom Date"
        }
    ];

    const clearFilterBox = () => {
        if (props.orderStatus && props.orderStatus !== null) {
            const element = document.getElementById(props.orderStatus);
            if (element) {
                element.checked = false;
            }
        };
        if (props.orderType && props.orderType !== null) {
            const element = document.getElementById(props.companyId);
            if (element) {
                element.checked = false;
            }
        };

        if (props.serviceId !== null) {
            document.getElementById(props.serviceId).checked = false;
        };
        if (props.customerId !== null) {
            document.getElementById(props.customerId).checked = false;
        };

        if (props.orderHistoryDateLastUsedDate !== "default") {
            props.setOrderHistoryDateLastUsedDate("default");
            document.getElementById(props.orderHistoryDateLastUsedDate).checked = false;
        }
        props.setScrollToDivTop(true);
        setSelectedStatus([]);
        setSelectedOrderTypes([]);
        props.setServiceId(null);
        props.setCustomerId(null);
        props.setOrderHistoryDateFrom(null);
        props.setOrderHistoryDateTo(null);
        props.setOrderStatus(null);
        props.setOrderType(null);
        setOrderHistoryDateCustomCheck(false);
        props.setCustomerLastUsed("default");
        props.setOrderHistoryDateCustomFromLast(date.toISOString().slice(0, 10));
        props.setOrderHistoryDateCustomToLast(date.toISOString().slice(0, 10));
        props.setStatusLastUsed("default");
        props.setPageNo(1);
        props.GetTableData(1, props.pageSize, props.searchValueTable, null, null, null, props.sortCol, props.sortDir, null, [], []);
        props.setFilterFlag(false);
        // props.handleClearFilter();

    }

    useEffect(() => {
        if (props.serviceId == 29) {
            setShowOrderTypeDropdown(true);
            GetAllOrderType();                          // Load the order types if Google Workspace is selected
        } else {
            setShowOrderTypeDropdown(false);
            // If a different service is selected, reset the orderTypeData state
            setOrderTypeData(null);
        }
    }, [props.serviceId]);

    useEffect(() => {
        (props.orderStatus != null || props.orderStatus != undefined) ? setSelectedStatus(props.orderStatus) : setSelectedStatus([]);
        (props.orderType != null || props.orderType != undefined) ? setSelectedOrderTypes(props.orderType) : setSelectedOrderTypes([]);
    }, []);

    return (
        <div className="filter-box filter-box-md">
            <Container>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className="mb-3 mobile-filter-row">
                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Service</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchServiceValue}
                                    onChange={(e) => setSearchServiceValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchService}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">
                                {
                                    props.serviceData &&
                                    props.serviceData.filter(option => (option.name).toLowerCase().includes(searchServiceValue.toLowerCase())).map((filteredOption) => {
                                        if (filteredOption.serviceType == "ONLINE") {
                                            return (
                                                <>
                                                    <Form.Check // prettier-ignore
                                                        key={filteredOption.id}
                                                        type={'radio'}
                                                        id={filteredOption.id}
                                                        label={filteredOption.name}
                                                        name="servicesGroup"
                                                        value={filteredOption.id}
                                                        aria-label={filteredOption.name}
                                                        defaultChecked={filteredOption.id == props.serviceId ? true : false}
                                                        onChange={(e) => { (e.target.value === "default") ? props.setServiceId(null) : props.setServiceId(e.target.value); props.setOrderHistoryServiceLastUsed(e.target.value); }}
                                                    />
                                                </>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                    {showOrderTypeDropdown &&
                        <Col className="filter-column">
                            {/* { Order Type dropdown } */}
                            <>
                                <h3 className="mb-3">Order Type</h3>
                                <div>
                                    <div className="filter-options">
                                        <>
                                            {orderTypeData && orderTypeData.length > 0 && <Form.Check
                                                className="mb-2"
                                                type="radio"
                                                id={`services-all`}
                                                label="All Order Types"
                                                checked={(selectedOrderTypes.length === orderTypeData.length)}
                                                onChange={(e) => handleAllOrderTypeChange(e.target.checked)}
                                            />}
                                            {orderTypeData && orderTypeData.length > 0 &&
                                                orderTypeData.map((orderItem) => {
                                                    return (
                                                        <Form.Check
                                                            className="mb-2"
                                                            key={orderItem.id}
                                                            type="radio"
                                                            id={`service-${orderItem.id}`}
                                                            label={orderItem.name}
                                                            checked={selectedOrderTypes.includes(orderItem.id) && !((selectedOrderTypes.length === orderTypeData.length))}
                                                            onChange={() => setSelectedOrderTypes(orderItem.id)}
                                                        />
                                                    );
                                                })}
                                            {
                                                !orderTypeData && <>Loading...</>
                                            }
                                        </>
                                    </div>
                                </div>
                                <br />
                            </>
                        </Col>
                    }

                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Customer</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchCustomerValue}
                                    onChange={(e) => setSearchCustomerValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchCustomer}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>

                            <div className="filter-options">
                                {
                                    props.customerData &&
                                    props.customerData.filter(option => (option.name).toLowerCase().includes(searchCustomerValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.id}
                                                    type={'radio'}
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="customerGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.customerId ? true : false}
                                                    onChange={(e) => { (e.target.value === "default") ? props.setCustomerId(null) : props.setCustomerId(e.target.value); props.setCustomerLastUsed(e.target.value); }}
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    !props.customerData && <>Loading...</>
                                }

                            </div>
                        </div>
                    </Col>

                    <Col className="filter-column" md={2}>
                        <h3 className="mb-3">Status</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.statusData && props.statusData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="radio"
                                        id={`statuses-all`}
                                        label="All Statuses"
                                        checked={(selectedStatus.length === props.statusData.length)}
                                        onChange={(e) => handleAllStatusChange(e.target.checked)}
                                    />}
                                    {props.statusData && props.statusData.length > 0 &&
                                        props.statusData.map((statusItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="radio"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatus.includes(statusItem.id) && !((selectedStatus.length === props.statusData.length))}
                                                    onChange={() => setSelectedStatus(statusItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>
                    </Col>
                    <Col className="filter-column" md={3}>
                        <h3 className="mb-3"> Date</h3>
                        <div>

                            <div className="filter-options date-filter-options">
                                <Form>
                                    {
                                        dateArray.map(orderHistoryDate => {
                                            return (
                                                <>
                                                    < Form.Check // prettier-ignore
                                                        key={orderHistoryDate.value}
                                                        type={'radio'}
                                                        id={orderHistoryDate.value}
                                                        label={orderHistoryDate.name}
                                                        name="orderHistoryDateGroup"
                                                        value={orderHistoryDate.value}
                                                        aria-label={orderHistoryDate.name}
                                                        defaultChecked={orderHistoryDate.value == props.orderHistoryDateLastUsedDate ? true : false}
                                                        onChange={(e) => {
                                                            if (e.target.value === "custom") {
                                                                props.setOrderHistoryDateFrom(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()));
                                                                props.setOrderHistoryDateTo(String(`${String(date.getDate()).padStart(2, '0')}`) + "/" + String(`${String(date.getMonth() + 1).padStart(2, "0")}`) + "/" + String(date.getFullYear()))
                                                            }

                                                            DatePicker(e, props.setOrderHistoryDateFrom, props.setOrderHistoryDateTo, setOrderHistoryDateCustomCheck); props.setOrderHistoryDateLastUsedDate(e.target.value);
                                                        }}
                                                    />
                                                </>
                                            )

                                        })
                                    }
                                    {
                                        (orderHistoryDateCustomCheck || props.orderHistoryDateLastUsedDate === "custom") &&
                                        <>
                                            <p className='mt-2'>From :</p>
                                            <Form.Control value={props.orderHistoryDateCustomFromLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setOrderHistoryDateCustomFromLast(null); props.setOrderHistoryDateFrom(null); } else { props.setOrderHistoryDateCustomFromLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setOrderHistoryDateFrom(String(date[0] + "/" + date[1] + "/" + date[2])); } }} />
                                            <p className='mt-2'>To :</p>
                                            <Form.Control value={props.orderHistoryDateCustomToLast} max="2999-12-31" type="date" onChange={(e) => { if (!(e.target.value)) { props.setOrderHistoryDateCustomToLast(null); props.setOrderHistoryDateTo(null); } else { props.setOrderHistoryDateCustomToLast(e.target.value); let date = (e.target.value.split("-")).reverse(); props.setOrderHistoryDateTo(String(date[0] + "/" + date[1] + "/" + date[2])) } }} />
                                        </>
                                    }
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.serviceId && !props.orderHistoryDateFrom && !props.orderHistoryDateTo && !props.customerId && !props.orderStatus && !props.orderType && selectedOrderTypes.length == 0 && selectedStatus.length == 0) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.serviceId || props.orderHistoryDateFrom || props.orderHistoryDateTo || props.customerId || props.orderStatus || props.orderType || selectedOrderTypes.length > 0 || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}
                            >
                                Clear
                            </Button>
                        }

                        {(props.serviceId || props.orderHistoryDateFrom || props.orderHistoryDateTo || props.customerId || props.orderStatus || props.orderType || selectedOrderTypes.length > 0 || selectedStatus.length > 0) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setScrollToDivTop(true);
                                    props.setOrderStatus(selectedStatus);
                                    props.setOrderType(selectedOrderTypes);
                                    props.setPageNo(1);
                                    props.setPagesWithInProgress(new Set()); // Reset pages with in-progress status
                                    props.setIsAutoRefreshEnabled(false); 
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.serviceId, props.orderHistoryDateFrom, props.orderHistoryDateTo, props.sortCol, props.sortDir, props.customerId, selectedStatus, selectedOrderTypes, false, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.serviceId && !props.orderHistoryDateFrom && !props.orderHistoryDateTo && !props.customerId && !props.orderStatus && selectedStatus.length == 0 && selectedOrderTypes.length == 0 && !props.orderType) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default OrderHistoryFilterBox